<template>
  <v-card>
    <v-card-title> Resources </v-card-title>
    <v-card-text>
      <v-row v-for="(resource, rIndex) in resources" :key="rIndex" dense>
        <v-col cols="12">
          <a
            @click="getClickableResource(resource.id)"
            style="text-decoration: none"
            target="_blank"
          >
            {{ resource.title }}
          </a>
        </v-col>

        <v-col cols="12">
          {{ resource.details }}
        </v-col>

        <v-col v-if="rIndex + 1 < resources.length" cols="12" class="ml-n4">
          <v-divider style="min-width: 216px" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'HomePageResourceMenu',
  data() {
    return {
      resources: [],
    };
  },
  mounted() {
    this.getCompanyResources();
  },
  methods: {
    getCompanyResources() {
      const url = 'get-company-resources?format=json';
      const params = {
        loaderText: 'Loading...',
      };

      this.$axios.post(url, params).then((response) => {
        this.buildCompanyResources(response.data);
      });
    },
    buildCompanyResources(rawResources) {
      for (const resource of rawResources) {
        if (resource.isEnabledWeb) {
          this.resources.push({
            id: resource.id,
            details: resource.details,
            title: resource.label,
          });
        }
      }

      this.$emit('onLoad', this.resources.length);
    },
    getClickableResource(resourceId) {
      const url = 'get-clickable-resource';
      const params = {
        loaderText: 'Loading...',
        resourceId: resourceId,
      };

      // Get resource at time of clicking in case it has been modified since last refresh
      this.$axios.post(url, params).then((response) => {
        if (response.data.url.length) {
          window.open(this.$helpers.safeUrl(response.data.url));
          return;
        }

        this.downloadResource(resourceId);
      });
    },
    downloadResource(resourceId) {
      const url = 'download-resource-pdf?format=json';
      const params = {
        loaderText: 'Loading...',
        resourceId: resourceId,
      };
      const options = {
        responseType: 'blob',
        withCredentials: process.env.VUE_APP_USE_WITH_CREDENTIALS,
      };

      this.$axios.post(url, params, options).then((response) => {
        this.$helpers.previewFileInNewWindow(response.data);
      });
    },
  },
};
</script>
