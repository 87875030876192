<template>
  <v-container fluid>
    <v-row v-if="!showDashboardPreview">
      <v-col class="grow">
        <v-card>
          <v-card-text>
            <!-- requiring my attention panel -->
            <v-expand-transition>
              <v-row v-if="$store.getters.requiringMyAttention > 0">
                <v-col>
                  <v-card
                    flat
                    :style="{ border: '1px solid red !important' }"
                    color="red lighten-5"
                  >
                    <v-card-text>
                      <v-row no-gutters align="center">
                        <v-col class="grow text-subtitle-1 red--text">
                          <v-icon color="red">
                            mdi-alert-octagon-outline
                          </v-icon>
                          {{
                            $store.getters.requiringMyAttention == 1
                              ? '1 thing needs'
                              : `${$store.getters.requiringMyAttention} things need`
                          }}
                          your attention right now.
                        </v-col>
                        <v-col class="shrink">
                          <v-btn
                            class="msaBlue white--text"
                            @click="
                              $router.push({ name: 'RequiringMyAttention' })
                            "
                          >
                            review now
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-expand-transition>

            <!-- quick actions -->
            <v-row align="center" dense>
              <v-col class="shrink">
                <FillOutNewDialog />
              </v-col>
              <v-spacer />
              <v-col align="right">
                <CustomDatePicker
                  ref="datePicker"
                  :from="dateRange.from"
                  :to="dateRange.to"
                  :filterIntervalId="dateRange.intervalId"
                  @update="updateDateRange"
                />
              </v-col>
              <v-col v-if="hasResources" class="shrink">
                <v-btn
                  @click="isResourcesOpen = !isResourcesOpen"
                  color="msaBlue white--text"
                >
                  Resources
                  <v-icon v-if="isResourcesOpen">mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <!-- dashboard cards -->
            <v-row>
              <v-col
                sm="6"
                md="4"
                v-for="(card, i) in dashboardCards"
                :key="i + 99"
              >
                <v-expand-transition v-if="card">
                  <v-card
                    class="d-flex flex-column justify-space-between"
                    height="320"
                    outlined
                  >
                    <v-card-title>{{ card.title }}</v-card-title>
                    <v-card-text class="py-0">
                      <v-row no-gutters>
                        <v-col cols="12" v-if="cardHasData(card)">
                          <Bar
                            :chart-options="chartOptions"
                            :chart-data="card"
                            :height="200"
                          />
                        </v-col>
                        <v-col
                          v-else
                          align="center"
                          class="grow text-subtitle-1"
                          cols="12"
                        >
                          No data available for the selected date range.
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-row>
                        <v-col
                          v-if="card.title != 'Assigned Form Status'"
                          align="center"
                        >
                          <v-btn
                            text
                            color="msaBlue"
                            @click="viewDetails(i + 1)"
                          >
                            view details
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-actions>
                  </v-card>
                </v-expand-transition>

                <!-- if a card in between two loaded cards is still loading,
                this part makes sure the loader displays properly -->
                <v-expand-transition v-else>
                  <v-skeleton-loader type="card-heading, image" />
                </v-expand-transition>
              </v-col>

              <v-col sm="6" md="4" v-for="n in loadingCards" :key="n">
                <v-expand-transition>
                  <v-skeleton-loader type="card-heading, image" />
                </v-expand-transition>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-show="hasResources && isResourcesOpen"
        class="shrink"
        style="min-width: 240px"
      >
        <HomePageResourcesMenu @onLoad="hasResources = $event" />
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col>
        <v-card
          :style="{
            display: 'flex',
            'justify-content': 'center',
            'align-content': 'center',
            'flex-direction': 'column',
          }"
          color="transparent"
          outlined
        >
          <v-img
            src="../assets/dashboard_preview.png"
            width="100%"
            height="100%"
            contain
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomDatePicker from '@/components/CustomDatePicker.vue';
import HomePageResourcesMenu from './../components/Menus/HomePageResourcesMenu.vue';
import FillOutNewDialog from '@/components/FormInstances/FillOutNewDialog.vue';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'vue-chartjs/legacy';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  ChartDataLabels,
);

export default {
  name: 'HomePage',
  components: {
    CustomDatePicker,
    HomePageResourcesMenu,
    FillOutNewDialog,
    Bar,
  },
  data() {
    return {
      hasResources: false,
      isResourcesOpen: true,
      resources: [],
      dateRange: {
        intervalId: this.$constants.FILTER_INTERVALS.LAST_30_DAYS,
        from: '',
        to: '',
      },
      dashboardCards: [],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        // horizontal bar chart
        indexAxis: 'y',
        scales: {
          x: {
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
            display: false,
          },
          y: {
            grid: {
              display: false,
            },
            // add padding to labels on the left to leave space for the value
            ticks: {
              padding: 40,
            },
          },
        },
        plugins: {
          // hide legend
          legend: { display: false },
          // show value on start of each bar
          datalabels: {
            anchor: 'start',
            align: 'start',
            formatter: (value) => value,
          },
        },
        // bar thickness
        barPercentage: 0.6,
        // disable hover event
        events: [],
      },
      maximumCardsAmount: this.$store.getters.isWebAdmin ? 7 : 4,
    };
  },
  computed: {
    showDashboardPreview() {
      return this.$store.getters.user.isFreeSubscriptionType;
    },
    loadingCards() {
      return this.maximumCardsAmount - this.dashboardCards.length;
    },
    cardHasData() {
      return (card) => card.datasets[0].data.some((value) => value > 0);
    },
    isWebAdmin() {
      return this.$store.getters.isWebAdmin;
    },
  },
  mounted() {
    if (!this.showDashboardPreview) {
      this.$refs.datePicker.init();
      // init values for from and to
      this.$refs.datePicker.updateFromAndTo(this.dateRange.intervalId);
    } else {
      //pinging the server just to fore a session
      this.$axios.post('public/ping?format=json', {});
    }
  },
  methods: {
    loadCard(position) {
      const params = {
        dateRange: this.dateRange,
        position,
      };
      const url = 'get-dashboard-card-data?format=json';
      return this.$axios.post(url, params).then((response) => {
        if (response.data === false) {
          this.maximumCardsAmount--;
        } else {
          const card = this.buildCard(position, response.data);
          this.$set(this.dashboardCards, position - 1, card);
        }
      });
    },
    updateDateRange(dateRange) {
      this.dateRange.intervalId = dateRange.filterIntervalId;
      this.dateRange.from = dateRange.from;
      this.dateRange.to = dateRange.to;
      this.dashboardCards = [];
      this.maximumCardsAmount = this.isWebAdmin ? 7 : 4;
      this.loadCards();
    },
    async loadCards() {
      const maxCalls = this.maximumCardsAmount;
      let calls = [];
      for (let i = 1; i <= maxCalls; i++) {
        calls.push(this.loadCard(i));

        // for every certain calls (based on the layout), wait for them to finish
        let callBatchSize = 3;
        if (this.$vuetify.breakpoint.smOnly) {
          callBatchSize = 2;
        }
        if (this.$vuetify.breakpoint.xsOnly) {
          callBatchSize = 1;
        }
        if (calls.length % callBatchSize === 0 || i === maxCalls) {
          let hasError = false;
          await Promise.all(calls).catch((error) => {
            hasError = true;
            return error;
          });
          if (hasError) {
            break;
          }
        }
      }
    },
    buildCard(position, payload) {
      const colors = this.$vuetify.theme.themes.light;
      const defaultColors = {
        red: '#F44336',
        green: '#4CAF50',
        yellow: '#FFEB3B',
        orange: '#FF9800',
      };
      switch (position) {
        case 1:
          return {
            title: 'Form Status',
            labels: ['Submitted', 'Reviewed', 'Finalized'],
            datasets: [
              {
                data: [
                  payload.formSubmittedCount,
                  payload.formReviewedCount,
                  payload.formFinalizedCount,
                ],
                backgroundColor: [colors.msaBlue],
              },
            ],
          };
        case 2:
          return this.isWebAdmin
            ? {
                title: 'Form Corrective Actions',
                labels: ['Unresolved', 'Resolved'],
                datasets: [
                  {
                    data: [
                      payload.correctiveActionUnresolvedCount,
                      payload.correctiveActionResolvedCount,
                    ],
                    backgroundColor: [defaultColors.red, defaultColors.green],
                  },
                ],
              }
            : {
                title: 'Training Record Status',
                labels: ['Completed', 'Expired'],
                datasets: [
                  {
                    data: [
                      payload.trainingRecordCompletedCount,
                      payload.trainingRecordExpiredCount,
                    ],
                    backgroundColor: [defaultColors.green, defaultColors.red],
                  },
                ],
              };
        case 3:
          return this.isWebAdmin
            ? {
                title: 'Training Record Status',
                labels: ['Completed', 'Expired'],
                datasets: [
                  {
                    data: [
                      payload.trainingRecordCompletedCount,
                      payload.trainingRecordExpiredCount,
                    ],
                    backgroundColor: [defaultColors.green, defaultColors.red],
                  },
                ],
              }
            : {
                title: 'Assigned Document Status',
                labels: ['Assigned', 'Submitted'],
                datasets: [
                  {
                    data: [
                      payload.documentAssignedCount,
                      payload.documentSubmittedCount,
                    ],
                    backgroundColor: [colors.msaBlue],
                  },
                ],
              };
        case 4:
          return this.isWebAdmin
            ? {
                title: 'Assigned Document Status',
                labels: ['Assigned', 'Submitted'],
                datasets: [
                  {
                    data: [
                      payload.documentAssignedCount,
                      payload.documentSubmittedCount,
                    ],
                    backgroundColor: [colors.msaBlue],
                  },
                ],
              }
            : {
                title: 'Online Course Status',
                labels: [
                  'Assigned',
                  'In Progress',
                  'Passed',
                  'Failed',
                  'Expired',
                ],
                datasets: [
                  {
                    data: [
                      payload.courseAssignedCount,
                      payload.courseInProgressCount,
                      payload.coursePassedCount,
                      payload.courseFailedCount,
                      payload.courseExpiredCount,
                    ],
                    backgroundColor: [
                      colors.msaBlue,
                      defaultColors.yellow,
                      defaultColors.green,
                      defaultColors.red,
                      defaultColors.red,
                    ],
                  },
                ],
              };
        case 5:
          return {
            title: 'Online Course Status',
            labels: ['Assigned', 'In Progress', 'Passed', 'Failed', 'Expired'],
            datasets: [
              {
                data: [
                  payload.courseAssignedCount,
                  payload.courseInProgressCount,
                  payload.coursePassedCount,
                  payload.courseFailedCount,
                  payload.courseExpiredCount,
                ],
                backgroundColor: [
                  colors.msaBlue,
                  defaultColors.yellow,
                  defaultColors.green,
                  defaultColors.red,
                  defaultColors.red,
                ],
              },
            ],
          };
        case 6:
          return {
            title: 'Assigned Form Status',
            labels: ['Created', 'Waiting to sync', 'Synced', 'Done'],
            datasets: [
              {
                data: [
                  payload.formCreatedCount,
                  payload.formWaitingToSyncCount,
                  payload.formSyncedCount,
                  payload.formDoneCount,
                ],
                backgroundColor: [
                  colors.msaBlue,
                  defaultColors.orange,
                  defaultColors.yellow,
                  defaultColors.green,
                ],
              },
            ],
          };
        case 7:
          return {
            title: 'Document Status',
            labels: ['Uploaded', 'Replaced', 'Deleted'],
            datasets: [
              {
                data: [
                  payload.documentUploadedCount,
                  payload.documentReplacedCount,
                  payload.documentDeletedCount,
                ],
                backgroundColor: [
                  colors.msaBlue,
                  defaultColors.orange,
                  defaultColors.red,
                ],
              },
            ],
          };
        default:
          return {};
      }
    },
    viewDetails(position) {
      let params = {};
      let route = '';
      switch (position) {
        case 1:
          route = this.isWebAdmin ? 'CompanyForms' : 'Forms';
          params = this.isWebAdmin
            ? {
                status: [
                  this.$constants.FORM_INSTANCE_STATUS.SUBMITTED,
                  this.$constants.FORM_INSTANCE_STATUS.REVIEWED,
                  this.$constants.FORM_INSTANCE_STATUS.FINALIZED,
                ].join(','),
                createdDateIntervalId: this.dateRange.intervalId,
                createdFrom: this.dateRange.from,
                createdTo: this.dateRange.to,
              }
            : {
                createdFrom: this.dateRange.from,
                createdTo: this.dateRange.to,
              };
          break;
        case 2:
          route = this.isWebAdmin ? 'CompanyForms' : 'TrainingRecords';
          params = this.isWebAdmin
            ? {
                hasCorrectiveActions: 1,
                createdDateIntervalId: this.dateRange.intervalId,
                createdFrom: this.dateRange.from,
                createdTo: this.dateRange.to,
              }
            : {};
          break;
        case 3:
          route = this.isWebAdmin
            ? 'CompanyTrainingRecords'
            : 'AssignedDocuments';
          params = this.isWebAdmin
            ? {}
            : {
                createdFrom: this.dateRange.from,
                createdTo: this.dateRange.to,
              };
          break;
        case 4:
          route = this.isWebAdmin ? 'CompanyAssignedDocuments' : '';
          params = this.isWebAdmin
            ? {
                createdFrom: this.dateRange.from,
                createdTo: this.dateRange.to,
              }
            : {};
          break;
        case 6:
          route = 'AssignedForms';
          params.status = this.$constants.ASSIGNED_FORM_STATUS.CREATED;
          break;
        case 7:
          route = 'CompanyDocumentLibrary';
          break;
        default:
          break;
      }
      if (this.isWebAdmin && position == 5) {
        const windowReference = window.open();
        this.$axios
          .post('get-transfer-token?format=json', {
            loaderText: 'Connecting....',
          })
          .then((response) => {
            const url =
              process.env.VUE_APP_BASE_URL +
              'security-service/cht-to-lms?format=json' +
              '&token=' +
              response.data +
              '&redirect=WebAdminCourseReports';
            windowReference.location = url;
          });
        return;
      }
      if (!this.isWebAdmin && position == 4) {
        const windowReference = window.open();
        const redirect = this.$store.getters.user.isSupervisor
          ? 'SupervisorCourseReports'
          : 'EmployeeMyCourses';
        this.$axios
          .post('get-transfer-token?format=json', {
            loaderText: 'Connecting....',
          })
          .then((response) => {
            const url =
              process.env.VUE_APP_BASE_URL +
              'security-service/cht-to-lms?format=json' +
              '&token=' +
              response.data +
              '&redirect=' +
              redirect;
            windowReference.location = url;
          });
        return;
      }

      if (route) {
        this.$router.push({ name: route, query: params });
      }
    },
  },
};
</script>

<style></style>
